.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-header {
  background-color: rgb(58, 98, 98);
  overflow: visible;
  width: 100%;
  text-align: center;
  color: white;
}

.Messages {
  height: 100vh;
  /* Replace with the desired height */
  overflow-y: scroll;
}


.Messages-list {
  padding: 20px 0;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  padding-left: 0;
  flex-grow: 1;
  overflow: auto;
  font-weight: 300;
  overflow-y: scroll;
}

.Messages-message {
  display: flex;
  margin-top: 10px;
}

.Messages-message.currentMember {
  flex-direction: row-reverse;
  text-align: right;
}

.Message-content {
  display: inline-block;
}

.currentMember>.Message-content {
  align-items: flex-end;
}

.Messages-message>.avatar {
  height: 39px;
  width: 39px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px -10px;
  opacity: 0.6;
}

.Message-content>.username {
  display: block;
  color: gray;
  font-size: 14px;
  padding-bottom: 4px;
}

.Message-content>.text {
  padding: 10px;
  max-width: 400px;
  margin: 0;
  color: #555;
  display: inline-block;
  border-radius: 12px;

}

form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto 40px;
}

input {
  padding: 5px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid rgb(58, 98, 98);
  flex-grow: 1;
}

button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: rgb(58, 98, 98);
  color: white;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
}